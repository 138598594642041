import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import { AdminAction, adminReducer } from "@admin/admin.reducer.ts";
import { firebaseService } from "@lib/firebase.ts";
import {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
} from "react";
import { useLocation } from "react-router-dom";

export type AdminState = {
  loading: boolean;
  isLogged: boolean;
  models: Model[];
  customModels: CustomModel[];
};

type AdminContextType = {
  state: AdminState;
  dispatch: Dispatch<AdminAction>;
};

export const AdminContext = createContext({} as AdminContextType);

type AdminProviderProps = {
  children: ReactNode;
};

export function AdminProvider({ children }: AdminProviderProps) {
  const location = useLocation();

  const [state, dispatch] = useReducer(adminReducer, {
    loading: true,
    isLogged: false,
    models: [],
    customModels: [],
  });

  useEffect(() => {
    dispatch({ type: "set_loading", payload: true });

    const unsubscribe = firebaseService.onAuthStateChanged((user) => {
      if (user) {
        dispatch({ type: "set_is_logged", payload: true });
      } else {
        dispatch({ type: "set_is_logged", payload: false });
      }
      dispatch({ type: "set_loading", payload: false });
    });

    if (!location.pathname.startsWith("/admin")) {
      dispatch({ type: "set_loading", payload: false });
    }

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AdminContext.Provider value={{ state, dispatch }}>
      {children}
    </AdminContext.Provider>
  );
}
