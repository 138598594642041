import { Button } from "@/components/ui/button";
import SearchBox from "@components/ui/SearchBox.tsx";
import { Command, CommandItem, CommandList } from "@components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import { ModelHit, getModelHitTitle, searchClient } from "@lib/algolia.ts";
import { cn } from "@lib/utils.ts";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { Check, ChevronsUpDown, TriangleAlert } from "lucide-react";
import React, { useContext, useState } from "react";
import { Hits, InstantSearch } from "react-instantsearch";

type ModelsComboboxContextType = {
  value?: ModelHit;
  select: (value: ModelHit) => void;
};

const ModelsComboboxContext = React.createContext<ModelsComboboxContextType>(
  {} as ModelsComboboxContextType,
);

type ModelsComboboxProps = {
  onSelect(model?: ModelHit): void;
  side?: PopoverContentProps["side"];
};

export default function ModelsCombobox({
  onSelect,
  side,
}: ModelsComboboxProps) {
  const [value, setValue] = useState<ModelHit>();
  const [open, setOpen] = useState(false);

  function select(model: ModelHit) {
    if (value?.objectID === model.objectID) {
      setValue(undefined);
      onSelect(undefined);
    } else {
      setValue(model);
      onSelect(model);
    }
    setOpen(false);
  }

  return (
    <ModelsComboboxContext.Provider value={{ value, select }}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="min-w-[200px] justify-between gap-2 normal-case"
          >
            {value ? getModelHitTitle(value) : "Modèles de Custom Lab..."}
            <ChevronsUpDown />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" side={side} align="end">
          <Command>
            <InstantSearch searchClient={searchClient} indexName="models">
              <SearchBox />
              <CommandList>
                <Hits hitComponent={Hit} />
              </CommandList>
            </InstantSearch>
          </Command>
        </PopoverContent>
      </Popover>
    </ModelsComboboxContext.Provider>
  );
}

function Hit({ hit }: { hit: ModelHit }) {
  const { value, select } = useContext(ModelsComboboxContext);

  return (
    <CommandItem className="" value={hit.name} onSelect={() => select(hit)}>
      <Check
        className={cn(
          "mr-2 h-4 w-4",
          value?.objectID === hit.objectID ? "opacity-100" : "opacity-0",
        )}
      />

      {hit.shopifyProduct ? (
        <span className="flex items-center text-orange-600">
          <TriangleAlert className="mr-1 h-3.5 w-3.5" />
          <span className="line-clamp-1">{getModelHitTitle(hit)}</span>
        </span>
      ) : (
        <span className="line-clamp-1">{getModelHitTitle(hit)}</span>
      )}
    </CommandItem>
  );
}
