import { api } from "@/lib/api";
import { Logo } from "@/types/custom-element.ts";
import { CustomModel } from "@/types/custom-model.ts";
import FilesImportDialog from "@components/files-import/files-import-dialog.tsx";
import { Button } from "@components/ui/button.tsx";
import LogosLibraryDialog from "@custom-model-editor/components/logos/logos-library-dialog.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import {
  addClassToElement,
  makeElementClickable,
  makeElementInteractive,
  makeElementNonInteractive,
  removeClassFromElement,
} from "@custom-model-editor/lib/svg.ts";
import { cn } from "@lib/utils.ts";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type StepsMenuLogosProps = React.ComponentPropsWithoutRef<"div">;

type UploadLogoState = "uploading" | "error";

export default function StepsMenuLogos({
  className,
  ...props
}: StepsMenuLogosProps) {
  const [openLogosLibraryDialog, setOpenLogosLibraryDialog] =
    useState<boolean>(false);
  const [openFilesImportDialog, setOpenFilesImportDialog] =
    useState<boolean>(false);

  const [uploadLogoState, setUploadLogoState] = useState<
    UploadLogoState | undefined
  >();
  const { state, dispatch } = useCustomModelEditorContext();
  const customModel = state.customModel as CustomModel;

  const { t } = useTranslation("steps-menu-logos");

  useEffect(() => {
    const logoElements = customModel.elements.filter(
      (el) => el.type === "logo",
    );
    logoElements.forEach((el) => {
      addClassToElement(customModel.id, el.id, "interactive");
      makeElementInteractive(customModel.id, el.id);
    });
    makeElementClickable(customModel.id, "logos", true);

    return () => {
      logoElements.forEach((el) => {
        removeClassFromElement(customModel.id, el.id, "interactive");
        makeElementNonInteractive(customModel.id, el.id);
      });
      makeElementClickable(customModel.id, "logos", false);
    };
  }, [customModel.id, customModel.elements]);

  function handleAddLogo(logo: Logo) {
    dispatch({ type: "add_logo", payload: logo });
    setOpenLogosLibraryDialog(false);
  }

  async function handleImportLogos(files: File[]) {
    try {
      setUploadLogoState("uploading");
      const uploadedLogos = await api.uploadCustomLogos(files);
      dispatch({
        type: "update_custom_model",
        payload: {
          importedLogos: [...customModel.importedLogos, ...uploadedLogos],
        },
      });
      setOpenFilesImportDialog(false);
      setOpenLogosLibraryDialog(true);
    } catch (error) {
      setUploadLogoState("error");

      console.error("Error uploading custom logos", error);
      Sentry.captureException(error, {
        tags: { section: "custom-logos-upload" },
      });
    }
  }

  return (
    <div className={cn(className)} {...props}>
      <div className="text-center text-xl font-black">{t("title")}</div>

      <div className="mb-2 p-2 text-center">{t("help-msg")}</div>

      <div className="flex flex-col items-stretch gap-3 px-2">
        <LogosLibraryDialog
          open={openLogosLibraryDialog}
          onOpenChange={setOpenLogosLibraryDialog}
          imported={customModel.importedLogos}
          onLogoClick={handleAddLogo}
        />

        <div className="text-center uppercase">{t("or-separator")}</div>

        <FilesImportDialog
          open={openFilesImportDialog}
          onOpenChange={(value) => {
            setOpenFilesImportDialog(value);
            setUploadLogoState(undefined);
          }}
          options={{
            accept: {
              "image/jpeg": [".jpeg", ".jpg"],
              "image/png": [".png"],
              "image/svg+xml": [".svg"],
            },
            maxSize: 1048576, // 1MB
          }}
          onSubmit={handleImportLogos}
          isUploading={uploadLogoState === "uploading"}
          hasError={uploadLogoState === "error"}
        >
          <Button className="border-2 border-primary bg-slate-100 uppercase text-primary hover:bg-slate-100/75">
            {t("upload-your-logo-btn")}
          </Button>
        </FilesImportDialog>
      </div>
    </div>
  );
}
