import { InfiniteHits } from "@/components/ui/InfiniteHits";
import { Button } from "@/components/ui/button";
import { useConfirm } from "@/providers/AlertDialogProvider.tsx";
import NewModelLink from "@admin/components/models/new-model-link.tsx";
import SearchBox from "@components/ui/SearchBox.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { ModelHit, searchClient } from "@lib/algolia.ts";
import { api } from "@lib/api.ts";
import { cn } from "@lib/utils.ts";
import * as Sentry from "@sentry/react";
import { Ellipsis } from "lucide-react";
import { Highlight, InstantSearch, RefinementList } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function AdminModelsRoute() {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="models">
        <div className="mb-3 flex justify-between">
          <SearchBox />
          <NewModelLink />
        </div>
        <RefinementList
          attribute="brand"
          className="mb-4"
          sortBy={["name:asc"]}
          limit={100}
          classNames={{
            list: "flex items-center flex-wrap gap-3",
            item: "text-black",
            count: "ml-1 bg-gray-200 text-gray-600 text-sm rounded px-1",
            checkbox: "mr-1 rounded-sm border-gray-400",
          }}
        />
        <InfiniteHits<ModelHit> HitComponent={Hit} />
      </InstantSearch>
    </div>
  );
}

function Hit({ hit }: { hit: ModelHit }) {
  const navigate = useNavigate();
  const confirm = useConfirm();

  function handleHitClick() {
    navigate(`/customize?model=${hit.objectID}`);
  }

  async function handleDeleteClick() {
    const confirmed = await confirm({
      title: `Supprimer le modèle ${hit.name} ?`,
      body: "Assurez-vous de bien déassocier le produit lié à ce modèle avant de le supprimer.",
      actionButton: "Supprimer",
      actionButtonVariant: "destructive",
    });

    if (confirmed) {
      const promise = api.deleteModel(hit.objectID);
      toast.promise(promise, {
        loading: "Suppression du modèle...",
        success: () => {
          window.location.reload();
          return "Modèle supprimé";
        },
        error: (error) => {
          console.error("Erreur lors de la suppression du modèle", error);
          Sentry.captureException(error, {
            tags: { section: "models" },
          });
          return "Erreur lors de la suppression";
        },
      });
    }
  }

  return (
    <div className="relative overflow-hidden rounded-lg bg-[#323232] p-2">
      <div
        className="group grid cursor-pointer grid-rows-[200px_auto] items-center gap-3"
        onClick={handleHitClick}
      >
        <img
          className={cn(
            "h-full w-full object-contain duration-100 group-hover:scale-110",
          )}
          src={hit.image}
          alt={hit.name}
        />

        <div className="flex flex-col items-center gap-1">
          {/* Brand */}
          <Highlight
            classNames={{
              root: "text-sm rounded-full bg-gray-500 px-2",
              highlighted: "bg-primary text-white",
            }}
            attribute="brand"
            hit={hit}
          />
          {/* Name */}
          <Highlight
            classNames={{
              root: "text-lg font-semibold",
              highlighted: "bg-primary text-white",
            }}
            attribute="name"
            hit={hit}
          />
        </div>
      </div>

      {/* Actions dropdown */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="absolute right-1 top-1 h-10 w-10 p-0"
            variant="ghost"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuGroup className="*:flex *:justify-between *:gap-2 *:font-normal">
            {/* ID */}
            <DropdownMenuLabel>
              <span>ID:</span>
              <span>{hit.objectID}</span>
            </DropdownMenuLabel>
            {/* CreatedAt */}
            <DropdownMenuLabel>
              <span>Créé le:</span>
              <span>{new Date(hit.createdAt).toLocaleString("fr")}</span>
            </DropdownMenuLabel>
            {/* UpdatedAt */}
            {hit.updatedAt ? (
              <DropdownMenuLabel>
                <span>Dernière màj:</span>
                <span>{new Date(hit.updatedAt).toLocaleString("fr")}</span>
              </DropdownMenuLabel>
            ) : null}
          </DropdownMenuGroup>

          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <DropdownMenuItem
              className="text-error hover:!bg-error/10 hover:!text-error"
              onClick={handleDeleteClick}
            >
              Supprimer
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
