import Admin from "@admin/components/admin.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { Navigate } from "react-router-dom";

export default function AdminRoute() {
  const {
    state: { loading, isLogged },
  } = useAdminContext();

  return loading ? (
    <div className="grid place-items-center">
      <MxlabLoader
        color="black"
        text="Chargement du panel d'administration..."
      />
    </div>
  ) : isLogged ? (
    <Admin />
  ) : (
    <Navigate to="login" />
  );
}
