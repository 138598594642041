import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import { ModelHit } from "@lib/algolia.ts";
import { cn, getModelTitle } from "@lib/utils.ts";
import React from "react";
import { Link } from "react-router-dom";

type ShopifyProductLinkModelProps = React.ComponentPropsWithoutRef<"div"> & {
  model: ModelHit;
};

export default function ShopifyProductLinkModel({
  className,
  model,
  ...props
}: ShopifyProductLinkModelProps) {
  return (
    <div
      className={cn("flex flex-col gap-3 overflow-hidden", className)}
      {...props}
    >
      <Link to={`/customize?model=${model.objectID}`} target="_blank">
        <div className="aspect-square max-h-[350px] w-full overflow-hidden rounded-lg border">
          <div className="h-full w-full cursor-pointer duration-200 hover:scale-110">
            <CustomModelSvgInjector
              customModel={{
                id: `product-link-preview`,
                // @ts-expect-error todo
                model,
                colorLayers: [],
                elements: [],
                importedLogos: [],
              }}
              interactive={false}
              Loader={() => (
                <div className="grid h-full w-full place-items-center">
                  <OvalLoader width={100} height={100} />
                </div>
              )}
            />
          </div>
        </div>
      </Link>

      <div className="mt-2 text-lg font-semibold text-slate-950">
        {/* @ts-expect-error todo*/}
        {getModelTitle(model)}
      </div>

      <div>
        <div className="flex justify-between">
          Crée le{" "}
          <span className="text-slate-950">
            {/* @ts-expect-error todo*/}
            {new Date(model.createdAt as Date).toLocaleDateString("fr", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>

        {model.updatedAt ? (
          <div className="flex justify-between">
            Dernière modif le{" "}
            <span className="text-slate-950">
              {new Date(model.updatedAt).toLocaleDateString("fr", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
