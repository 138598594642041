import { ColorElement } from "@/types/color-elements.ts";
import { CustomElement } from "@/types/custom-element.ts";
import {
  CustomModelEditorMode,
  CustomModelHistoryStatus,
  StepBarItem,
} from "@/types/custom-model-editor.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { LocalizedNotice } from "@/types/model.ts";
import { CustomModelEditorAction } from "@custom-model-editor/custom-model-editor.reducer.ts";
import { Dispatch, createContext } from "react";

export type CustomModelEditorState = {
  customModel: CustomModel;
  elementSelected: CustomElement | undefined;
  colorElementSelected: ColorElement | undefined;

  importedLogos: File[];

  noticeToShow: LocalizedNotice | undefined;

  stepBarItems: StepBarItem[];
  currentStepBarItem: StepBarItem | undefined;

  mode: CustomModelEditorMode;
  isSaving?: CustomModelHistoryStatus;
};

export type CustomModelEditorContextType = {
  state: CustomModelEditorState;
  dispatch: Dispatch<CustomModelEditorAction>;
};

export const CustomModelEditorContext = createContext(
  {} as CustomModelEditorContextType,
);
