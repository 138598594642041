import { CustomModelEditorMode } from "@/types/custom-model-editor.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { LocalizedNotice } from "@/types/model.ts";
import Sidebar from "@components/layout/sidebar.tsx";
import AdminToolbar from "@custom-model-editor/components/admin/admin-toolbar.tsx";
import NotAdminGuard from "@custom-model-editor/components/admin/not-admin-guard.tsx";
import CustomElementEditor from "@custom-model-editor/components/custom-elements/custom-element-editor.tsx";
import CustomLabAppTitle from "@custom-model-editor/components/custom-lab-app-title.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import HelpCard from "@custom-model-editor/components/help/help-card.tsx";
import LocaleSwitcher from "@custom-model-editor/components/locale-switcher.tsx";
import NoticeCard from "@custom-model-editor/components/notices/notice-card.tsx";
import SaveToShopifyBtn from "@custom-model-editor/components/step-bar/save-to-shopify-btn.tsx";
import StepBar from "@custom-model-editor/components/step-bar/step-bar.tsx";
import StepsMenu from "@custom-model-editor/components/steps-menus/steps-menu.tsx";
import Toolbar from "@custom-model-editor/components/toolbar.tsx";
import CustomModelHistory from "./custom-model-history";
import { CustomModelEditorProvider } from "@custom-model-editor/custom-model-editor.provider.tsx";
import { useElementSize } from "@custom-react-hooks/use-element-size";
import { cn } from "@lib/utils.ts";
import { CustomModelEditorContext } from "../custom-model-editor-context";

type CustomModelEditorProps = {
  customModel: CustomModel;
  onChange?: (customModel: CustomModel) => void;
  mode?: CustomModelEditorMode;
};

export default function CustomModelEditor({
  customModel,
  mode = "edit",
}: CustomModelEditorProps) {
  const [stepBarRef, stepBarSize] = useElementSize();

  return (
    <CustomModelEditorProvider customModel={customModel} mode={mode}>
      <CustomModelEditorContext.Consumer>
        {({ state, dispatch }) => {
          const { noticeToShow } = state;

          function handleNoticeClose(notice: LocalizedNotice) {
            dispatch({ type: "set_notice_viewed", payload: notice });
          }

          return (
            <>
              <Sidebar>
                <StepsMenu />
              </Sidebar>

              <CustomModelHistory />

              <div className="relative">
                <Toolbar />
                <CustomLabAppTitle />
                <NotAdminGuard>
                  <SaveToShopifyBtn className="absolute right-3 top-2 lg:right-4 lg:top-4 xl:right-6 xl:top-6" />
                </NotAdminGuard>
                <AdminToolbar />
                <CustomElementEditor />

                {noticeToShow ? (
                  <NoticeCard
                    className={cn(
                      "fixed left-[235px] top-2 lg:left-[280px] lg:top-3 xl:left-[350px] xl:top-6",
                      "max-h-[95dvh]",
                      "w-[calc(90dvw-300px)] lg:w-[900px] lg:max-w-[calc(90dvw-250px)] xl:max-w-[calc(90dvw-350px)] 2xl:w-[1000px]",
                      "z-10 animate-notice overflow-y-auto",
                    )}
                    localizedNotice={noticeToShow}
                    onClose={handleNoticeClose}
                  />
                ) : null}

                <CustomModelSvgInjector
                  customModel={state.customModel}
                  onCustomModelUpdate={(updates) =>
                    dispatch({ type: "update_custom_model", payload: updates })
                  }
                  onElementSelect={(elementId) =>
                    dispatch({ type: "select_element", payload: elementId })
                  }
                  onElementUpdate={(elementId, updates) =>
                    dispatch({
                      type: "update_element",
                      payload: { elementId, updates },
                    })
                  }
                  onElementRemove={(elementId) =>
                    dispatch({ type: "remove_element", payload: elementId })
                  }
                />

                {state.customModel.model.modelConfig.notice === false ? (
                  <LocaleSwitcher
                    triggerClassName="absolute right-1.5 bottom-[50px] lg:bottom-[80px] lg:right-3 xl:bottom-[100px] xl:right-4"
                    contentProps={{ align: "end" }}
                  />
                ) : (
                  <HelpCard
                    style={{
                      transform: `translateY(-${stepBarSize.height + 15}px)`,
                    }}
                  />
                )}

                <StepBar ref={stepBarRef} />
              </div>
            </>
          );
        }}
      </CustomModelEditorContext.Consumer>
    </CustomModelEditorProvider>
  );
}
