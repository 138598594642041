import { ScreenshotProvider } from "@/_screenshots/screenshot.provider.tsx";
import { AlertDialogProvider } from "@/providers/AlertDialogProvider.tsx";
import { AdminProvider } from "@admin/admin.provider.tsx";
import Main from "@components/layout/main.tsx";
import { Toaster as ToasterSonner } from "@components/ui/sonner.tsx";
import { Toaster } from "@components/ui/toaster.tsx";
import "@lib/sentry.ts";
import Modal from "react-modal";
import { Outlet } from "react-router-dom";

Modal.setAppElement("#root");

function App() {
  return (
    <AlertDialogProvider>
      <AdminProvider>
        <ScreenshotProvider>
          <Main>
            <Outlet />
          </Main>
          <Toaster />
          <ToasterSonner />
        </ScreenshotProvider>
      </AdminProvider>
    </AlertDialogProvider>
  );
}

export default App;
