import { ProductsParams, ProductsResponse } from "@/types/api.ts";
import { Logo } from "@/types/custom-element.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import { ShopifyProduct } from "@/types/shopify.ts";
import { firebaseService } from "@lib/firebase.ts";
import { localStorageService } from "@lib/local-storage.ts";
import { makeId } from "@lib/utils.ts";
import { httpsCallable } from "firebase/functions";
import { ListResult } from "firebase/storage";

async function getShopifyProducts(
  cursor?: string,
  query?: string,
): Promise<ProductsResponse> {
  const { data } = await httpsCallable<ProductsParams, ProductsResponse>(
    firebaseService.functions,
    "products",
  )({ cursor, query });

  return data;
}

async function linkShopifyProductToCustomLabModel(
  product: ShopifyProduct,
  modelId = "",
): Promise<{ product: ShopifyProduct; model: Model }> {
  const metafieldId = product.metafields.nodes.find(
    (mf) => mf.key === "custom_lab_model_id",
  )?.id;

  const input = metafieldId
    ? {
        metafieldId,
        value: modelId,
      }
    : {
        namespace: "custom",
        key: "custom_lab_model_id",
        value: modelId,
      };

  const linkData = httpsCallable(
    firebaseService.functions,
    "linkToCustomLabModel",
  )({ productId: product.id, input });

  const { data } = await linkData;
  return data as { product: ShopifyProduct; model: Model };
}

function createCustomModel(model: Model): CustomModel {
  const newCustomModel = {
    id: makeId(20),
    model,
    colorLayers: [],
    elements: [],
    importedLogos: [],
  };
  return newCustomModel;
}

function saveCustomModelToDb(customModel: CustomModel): Promise<void> {
  const createdAt = customModel.createdAt ? customModel.createdAt : new Date();

  const toSave = {
    ...customModel,
    createdAt,
    updatedAt: new Date(),
  };

  return firebaseService.createCustomModel(toSave);
}

/**
 * Delete in db
 * @param customModelId
 */
function deleteCustomModel(customModelId: string) {
  return firebaseService.deleteCustomModel(customModelId);
}

async function getSavedCustomModel(
  modelId: string,
): Promise<CustomModel | undefined> {
  const sessionId = localStorageService.getSessionId();
  return firebaseService.getCustomModelHistoryById(sessionId, modelId);
}

async function deleteSavedCustomModel(modelId: string) {
  const sessionId = localStorageService.getSessionId();
  return firebaseService.deleteCustomModelHistory(sessionId, modelId);
}

function getCustomModelById(
  customModelId: string,
): Promise<CustomModel | undefined> {
  return firebaseService.getCustomModelById(customModelId);
}

async function updateCustomModel(customModel: CustomModel): Promise<void> {
  const sessionId = localStorageService.getSessionId();
  return firebaseService.upsertCustomModelHistory(sessionId, customModel);
}

function getModels(): Promise<Model[]> {
  return firebaseService.getModels();
}

async function createModel(model: Model) {
  await firebaseService.createModel({
    ...model,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
}

async function updateModel(model: Model) {
  await firebaseService.updateModel({
    ...model,
    updatedAt: new Date(),
  });
}

function getModelById(modelId: string): Promise<Model | undefined> {
  return firebaseService.getModelById(modelId);
}

function deleteModel(modelId: string): Promise<void> {
  return firebaseService.deleteModel(modelId);
}

async function uploadCustomizationScreenshot(
  customModelId: string,
  imageBlob: Blob,
): Promise<void> {
  const fullpath = `Custom Models/${customModelId}.png`;
  await firebaseService.uploadFile(fullpath, imageBlob);
}

async function uploadModelScreenshot(
  modelId: string,
  imageBlob: Blob,
): Promise<void> {
  const fullpath = `Models/${modelId}.png`;
  await firebaseService.uploadFile(fullpath, imageBlob);
}

async function uploadModelSVG(filename: string, svg: Blob): Promise<string> {
  const fullpath = `SVG/${filename}.svg`;
  await firebaseService.uploadFile(fullpath, svg);
  return firebaseService.getDownloadURL(fullpath);
}

async function uploadLogos(files: File[]): Promise<Logo[]> {
  const logoPromises = files.map(async (file: File) => {
    await firebaseService.uploadFile(`Logos/${file.name}`, file);
    return {
      name: file.name,
      url: await firebaseService.getDownloadURL(`Logos/${file.name}`),
    };
  });
  return Promise.all(logoPromises);
}

async function uploadCustomLogos(files: File[]): Promise<Logo[]> {
  const logoPromises = files.map(async (file: File) => {
    const timestamp = new Date().getTime();
    await firebaseService.uploadFile(
      `Custom_Logos/${timestamp}-${file.name}`,
      file,
    );
    return {
      name: file.name,
      url: await firebaseService.getDownloadURL(
        `Custom_Logos/${timestamp}-${file.name}`,
      ),
    };
  });
  return Promise.all(logoPromises);
}

async function getLogos(): Promise<Logo[]> {
  const files: ListResult = await firebaseService.getFolder("Logos");
  const logoPromises = files.items.map(async (file) => ({
    name: file.name,
    url: await firebaseService.getDownloadURL(file.fullPath),
  }));
  return Promise.all(logoPromises);
}

function deleteLogos(logos: Logo[]): Promise<void[]> {
  return firebaseService.deleteLogos(logos);
}

function getSessionId(): string {
  return localStorageService.getSessionId();
}

export const api = {
  getModels,
  getShopifyProducts,
  linkShopifyProductToCustomLabModel,
  createCustomModel,
  saveCustomModelToDb,
  getSavedCustomModel,
  deleteSavedCustomModel,
  getCustomModelById,
  updateCustomModel,
  deleteCustomModel,
  createModel,
  updateModel,
  getModelById,
  deleteModel,
  uploadCustomizationScreenshot,
  uploadModelScreenshot,
  uploadModelSVG,
  uploadLogos,
  uploadCustomLogos,
  getLogos,
  deleteLogos,
  getSessionId,
};
