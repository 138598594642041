import { api } from "@/lib/api";
import { CustomModel } from "@/types/custom-model";
import { CustomModelHistoryStatus } from "@/types/custom-model-editor";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCustomModelEditorContext } from "../hooks/use-custom-model-editor-context";

export default function CustomModelHistory() {
  const {
    state: { customModel },
    dispatch,
  } = useCustomModelEditorContext();

  const [previousCustomModel, setPreviousCustomModel] =
    useState<CustomModel | null>(null);

  const debouncedUpdateCustomModel = useDebouncedCallback(
    async (customModel: CustomModel) => {
      try {
        await api.updateCustomModel(customModel);
        dispatch({
          type: "set_saving_status",
          payload: CustomModelHistoryStatus.SAVED,
        });
      } catch (error) {
        console.error("Error saving custom model history", error);
        Sentry.captureException(error, {
          tags: { section: "custom-model-history" },
        });
        dispatch({
          type: "set_saving_status",
          payload: CustomModelHistoryStatus.ERROR,
        });
      }
    },
    3000,
    { maxWait: 30000 },
  );

  useEffect(() => {
    if (
      customModel &&
      customModel.colorLayers.length !== 0 &&
      customModel.elements?.length !== 0
    ) {
      if (!previousCustomModel || previousCustomModel.id !== customModel.id) {
        setPreviousCustomModel(customModel);
        return;
      }
      setPreviousCustomModel(customModel);
      dispatch({
        type: "set_saving_status",
        payload: CustomModelHistoryStatus.SAVING,
      });
      debouncedUpdateCustomModel(customModel);
    }
  }, [customModel]);

  return null;
}
