import { cn } from "@lib/utils.ts";
import type { BaseHit } from "instantsearch.js";
import { ComponentType, useEffect, useRef } from "react";
import { InfiniteHitsProps, useInfiniteHits } from "react-instantsearch";

type Props<T extends BaseHit> = InfiniteHitsProps<T> & {
  HitComponent: ComponentType<{ hit: T }>;
};

export function InfiniteHits<T extends BaseHit>({
  HitComponent,
  className,
  classNames,
  ...props
}: Props<T>) {
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <div className={cn(className, classNames?.root)}>
      <ul className={cn("grid grid-cols-3 gap-4", classNames?.list)}>
        {items.map((hit) => (
          <li key={hit.objectID} className={classNames?.item}>
            <HitComponent hit={hit} />
          </li>
        ))}
        <li ref={sentinelRef} aria-hidden="true" />
      </ul>
    </div>
  );
}
