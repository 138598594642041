import { CustomModel } from "@/types/custom-model.ts";
import { LocalizedNotice } from "@/types/model.ts";

const STATE_KEY = "customlab:state";

export type LocalStorageState = {
  customizations?: CustomModel[];
  noticesViewed?: string[];
};

function getState(): LocalStorageState | undefined {
  const state = localStorage.getItem(STATE_KEY);
  if (!state) return;
  return JSON.parse(state);
}

function saveState(state: LocalStorageState) {
  const currState = getState();
  localStorage.setItem(STATE_KEY, JSON.stringify({ ...currState, ...state }));
}

function saveCustomModel(customModel: CustomModel) {
  const state = getState();
  if (!state) {
    localStorage.setItem(
      STATE_KEY,
      JSON.stringify({
        customizations: [customModel],
      }),
    );
  } else {
    try {
      const customizations = [
        ...(state.customizations?.length === 10
          ? state.customizations?.slice(1) || []
          : state.customizations || []),
        customModel,
      ];

      localStorage.setItem(
        STATE_KEY,
        JSON.stringify({
          ...state,
          customizations,
        }),
      );
    } catch (err) {
      console.log("Error saving custom model");
      console.error(err);
      localStorage.setItem(
        STATE_KEY,
        JSON.stringify({
          ...state,
          customizations: [customModel],
        }),
      );
    }
  }
}

function resetState() {
  localStorage.setItem(STATE_KEY, JSON.stringify({ customizations: [] }));
}

function resetCustomization(modelId: string) {
  const state = getState();
  if (!state) return;
  localStorage.setItem(
    STATE_KEY,
    JSON.stringify({
      ...state,
      customizations: state.customizations?.filter(
        (cm) => cm.model.id !== modelId,
      ),
    }),
  );
}

function setNoticeViewed(localizedNotice: LocalizedNotice) {
  const state = getState();
  if (state?.noticesViewed?.includes(localizedNotice.fr.id)) return;
  localStorage.setItem(
    STATE_KEY,
    JSON.stringify({
      ...state,
      noticesViewed: [...(state?.noticesViewed || []), localizedNotice.fr.id],
    }),
  );
}

function isNoticeViewed(localizedNotice: LocalizedNotice): boolean {
  const state = getState();
  if (!state) return false;
  return !!state.noticesViewed?.includes(localizedNotice.fr.id);
}

function getSessionId(): string {
  let sessionId = localStorage.getItem("customlab:sessionId");
  if (!sessionId) {
    const uuid = window?.crypto?.randomUUID();
    if(!uuid) {
      sessionId = new Date().getTime().toString();
    }
    sessionId = uuid;
    localStorage.setItem("customlab:sessionId", sessionId);
  }
  return sessionId;
}

export const localStorageService = {
  getState,
  saveState,
  saveCustomModel,
  resetState,
  resetCustomization,
  setNoticeViewed,
  isNoticeViewed,
  getSessionId
};
