import { useScreenshotContext } from "@/_screenshots/use-screenshot-context.ts";
import { canvasToBlob, cn } from "@/lib/utils";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Button, ButtonProps } from "@components/ui/button.tsx";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { api } from "@lib/api.ts";
import { firebaseService } from "@lib/firebase.ts";
import * as Sentry from "@sentry/browser";
import { FirestoreError } from "firebase/firestore";
import { ShoppingCart } from "lucide-react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "sonner";

type SaveToShopifyBtnProps = ButtonProps;

export default function SaveToShopifyBtn({
  className,
  ...props
}: SaveToShopifyBtnProps) {
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [showAlertDialogError, setShowAlertDialogError] =
    useState<boolean>(false);

  const {
    state: { customModel },
  } = useCustomModelEditorContext();
  const { screenshot } = useScreenshotContext();

  const { t } = useTranslation("save-to-shopify-btn");

  const shopifyProduct = useMemo(
    () => customModel.model.shopifyProduct,
    [customModel.model.shopifyProduct],
  );

  function handleClick() {
    if (!shopifyProduct) return;

    screenshot(customModel, {
      callback: handleScreenshot,
    });
  }

  async function handleScreenshot(canvas: HTMLCanvasElement) {
    if (!shopifyProduct) return;

    setRedirecting(true);

    try {
      const imageBlob = await canvasToBlob(canvas);
      await api.uploadCustomizationScreenshot(customModel.id, imageBlob);
    } catch (err) {
      console.log("Error creating and uploading customization screenshot");
      console.error(err);
      Sentry.captureException(err);
    }

    try {
      const imageUrl = await firebaseService.getDownloadURL(
        `Custom Models/${customModel.id}.png`,
      );
      await api.saveCustomModelToDb({ ...customModel, image: imageUrl });
      const redirectUrl = `${shopifyProduct.onlineStoreUrl || shopifyProduct.onlineStorePreviewUrl}?custom_model_id=${customModel.id}`;
      window.location.href = redirectUrl;
    } catch (err) {
      console.log("Error saving custom model to db");
      console.error(err);

      toast.error(t("error-save-customization"), {
        duration: Number.POSITIVE_INFINITY,
        closeButton: true,
      });

      if (
        (err as FirestoreError).code === "invalid-argument" &&
        (err as FirestoreError).message.includes(
          "exceeds the maximum allowed size",
        )
      ) {
        setShowAlertDialogError(true);
      }

      Sentry.captureException(err);
    } finally {
      setRedirecting(false);
    }
  }

  return shopifyProduct ? (
    <>
      <Button
        className={cn(
          "h-8 text-sm font-semibold uppercase lg:h-9 lg:text-base",
          className,
        )}
        size="sm"
        onClick={handleClick}
        {...props}
      >
        {t("btn-text")} <ShoppingCart className="ml-1" size="20" />
      </Button>

      {redirecting ? (
        <div
          className={cn(
            "fixed bottom-0 left-0 right-0 top-0",
            "grid place-items-center",
            "bg-transparent/40",
            "z-50",
          )}
        >
          <MxlabLoader
            text={t("redirecting-msg")}
            textClassName="bg-[#323232]/75 px-2 rounded-sm"
          />
        </div>
      ) : null}

      <AlertDialog
        open={showAlertDialogError}
        onOpenChange={setShowAlertDialogError}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="mb-1">
              {t("error-save-max-size-title")}
            </AlertDialogTitle>
            <AlertDialogDescription className="flex flex-col gap-2">
              <div>
                <Trans
                  ns="save-to-shopify-btn"
                  i18nKey="error-save-max-size-msg-1"
                  components={{ bold: <span className="font-bold" /> }}
                />
              </div>
              <div>
                <Trans
                  ns="save-to-shopify-btn"
                  i18nKey="error-save-max-size-msg-2"
                  components={{ bold: <span className="font-bold" /> }}
                />
              </div>
              <div>{t("error-save-max-size-msg-3")}</div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t("error-save-max-size-cancel")}
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  ) : null;
}
