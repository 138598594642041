import FileUploadedCard, {
  FileUploaded,
} from "@components/files-import/file-uploaded-card.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import { Input } from "@components/ui/input.tsx";
import { Separator } from "@components/ui/separator.tsx";
import { cn, makeId } from "@lib/utils.ts";
import { DialogProps } from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { FilePlus2, Import, LoaderCircleIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";

type FilesImportDialogProps = DialogProps & {
  children: React.ReactNode;
  options?: Omit<DropzoneOptions, "onDropAccepted" | "onDropRejected">;
  onSubmit: (files: File[]) => Promise<void>;
  isUploading?: boolean
  hasError?: boolean;
};

export default function FilesImportDialog({
  children,
  options: { ...options },
  onSubmit,
  isUploading,
  hasError,
  ...props
}: FilesImportDialogProps) {
  const [uploadedFiles, setUploadedFiles] = useState<FileUploaded[]>([]);

  const { t } = useTranslation("files-import-dialog");

  // is valid if no files have errors and the number of files is less than the max
  const isValid = useMemo(() => {
    return !(
      uploadedFiles.some((file) => !!file.errors) ||
      (options.maxFiles && uploadedFiles.length > options.maxFiles)
    );
  }, [uploadedFiles, options]);

  useEffect(() => {
    setUploadedFiles([]);
  }, [props.open]);

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    maxFiles: undefined,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
  });

  function handleDropAccepted(files: File[]) {
    setUploadedFiles((prevState) => [
      ...prevState,
      ...files.map((file) => ({
        id: makeId(20),
        imageUrl: URL.createObjectURL(file),
        file: file,
        size: file.size,
      })),
    ]);
  }

  function handleDropRejected(fileRejections: FileRejection[]) {
    setUploadedFiles((prevState) => [
      ...prevState,
      ...fileRejections.map((fileRejection) => ({
        id: makeId(20),
        imageUrl: URL.createObjectURL(fileRejection.file),
        file: fileRejection.file,
        size: fileRejection.file.size,
        errors: fileRejection.errors,
      })),
    ]);
  }

  function handleFileRemove(file: FileUploaded) {
    setUploadedFiles((prevState) =>
      prevState.filter((uploadedFile) => uploadedFile.id !== file.id),
    );
  }

  return (
    <Dialog {...props}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className={cn(
          "w-[768px] max-w-[90dvw] xl:w-[980px]",
          "gap-2 border-transparent bg-[#323232] p-8 text-slate-50 lg:gap-4 lg:p-10 xl:gap-8 xl:p-12",
          "[&_.dialog-close>svg]:!text-slate-50",
          { "pb-2 lg:pb-4 xl:pb-8": uploadedFiles.length },
        )}
        aria-label="Import de fichiers"
      >
        <VisuallyHidden>
          <DialogTitle>{t("dialog-title")}</DialogTitle>
          <DialogDescription>{t("dialog-description")}</DialogDescription>
        </VisuallyHidden>
        <div
          {...getRootProps({
            className: cn(
              "dropzone w-full lg:h-[150px] xl:h-[300px] flex flex-col items-center justify-center  gap-1 lg:gap-4 py-3 lg:p-1",
              "bg-[#696969] rounded-lg border border-dashed border-2 border-[#ccc]",
              "cursor-pointer hover:opacity-75 text-center text-sm lg:text-base",
            ),
          })}
        >
          <Input {...getInputProps()} />
          <FilePlus2 className="mb-2 hidden h-12 w-12 xl:block" />

          <p className="text-slate-50">
            <Trans ns="files-import-dialog" i18nKey="drag-n-drop-msg">
              Glissez-déposez ou
              <span className="underline underline-offset-2">
                choisissez vos fichiers.
              </span>
            </Trans>
          </p>

          {/* options */}
          <div className="flex flex-col items-center">
            {options?.accept ? (
              <p className="font-light">
                <Trans
                  ns="files-import-dialog"
                  i18nKey="accept-msg"
                  values={{
                    types: Object.values(options.accept)
                      .join(",")
                      .replaceAll(",", ", "),
                  }}
                >
                  Seuls les fichiers de type
                  <span className="font-normal">types</span>
                  sont acceptés.
                </Trans>
              </p>
            ) : null}

            {options.maxSize ? (
              <p className="font-light">
                <Trans
                  ns="files-import-dialog"
                  i18nKey="max-size-msg"
                  values={{ size: options.maxSize / 1024 }}
                >
                  Taille maximale:
                  <span className="font-normal">size KB</span>
                </Trans>
              </p>
            ) : null}
          </div>
        </div>

        {uploadedFiles.length ? (
          <>
            <Separator className="bg-white/25" />

            <div
              className={cn(
                "grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3",
                "max-h-[calc(95dvh-200px)] lg:max-h-[calc(95dvh-370px)] xl:max-h-[calc(95dvh-600px)]",
                "fancy-scrollbar -mx-2 overflow-auto px-2",
              )}
            >
              {uploadedFiles.map((file) => (
                <FileUploadedCard
                  key={file.id}
                  file={file}
                  onRemove={handleFileRemove}
                />
              ))}
            </div>

            <DialogFooter className="!flex-col !items-center !justify-center gap-2">
              {/* too many files error message */}
              {options.maxFiles && uploadedFiles.length > options.maxFiles ? (
                <div className="col-span-3 text-center text-error">
                  {t("too-many-files-msg", { count: options.maxFiles })}
                </div>
              ) : null}

              {hasError && (
                <p className="text-error">{t("error")}</p>
              )}

              <Button
                variant="primary"
                className="mx-auto h-9 w-fit text-sm font-semibold uppercase lg:h-10 lg:text-base"
                disabled={!isValid || isUploading}
                onClick={() =>
                  isValid && onSubmit(uploadedFiles.map((f) => f.file))
                }
              >
                {isUploading ? 
                  <LoaderCircleIcon className="animate-spin mr-2 h-[16px] w-[16px] lg:h-[20px] lg:w-[20px]" />
                  :               
                  <Import className="mr-2 h-[16px] w-[16px] -translate-y-[1px] lg:h-[20px] lg:w-[20px]" />
                }
                {t("import-btn", { count: uploadedFiles.length })}
              </Button>
            </DialogFooter>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
