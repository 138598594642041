import { ShopifyProduct, ShopifyProductLink } from "@/types/shopify.ts";
import ShopifyProductStatusBadge from "@admin/components/shopify/shopify-product-status-badge.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table.tsx";
import { cn, getModelTitle } from "@lib/utils.ts";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TriangleAlert } from "lucide-react";
import { CSSProperties, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

type ShopifyProductLinkTableProps = {
  products: ShopifyProduct[];
  loading?: boolean;
  onRowClick?: (row: ShopifyProductLink) => void;
  nextPage: () => unknown;
  hasNextPage: boolean;

  tableClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  bodyStyle?: CSSProperties;
};

export default function ShopifyProductLinkTable({
  products,
  loading = false,
  onRowClick,
  nextPage,
  hasNextPage,
  tableClassName,
  headerClassName,
  bodyClassName,
  bodyStyle,
}: ShopifyProductLinkTableProps) {
  const {
    state: { models },
  } = useAdminContext();

  // @ts-expect-error todo
  const data: ShopifyProductLink[] = useMemo(
    () =>
      products
        .map((product) => {
          const metafield = product.metafields.nodes.find(
            (mf) => mf.key === "custom_lab_model_id",
          );
          const model = models.find((m) => m.id === metafield?.value);

          return {
            product,
            model,
          };
        })
        .sort((a, b) => (a.product.title < b.product.title ? -1 : 1)),
    [products, models],
  );

  const columns: ColumnDef<ShopifyProductLink>[] = [
    {
      id: "shopify-product",
      accessorFn: (row) => row.product,
      cell: (row) => (
        <>
          <div className="flex items-center gap-2">
            {row.getValue<ShopifyProduct>().featuredImage ? (
              <img
                className="aspect-square h-12 w-auto rounded-lg border object-contain"
                src={row.getValue<ShopifyProduct>().featuredImage.url}
                alt={`Image du produit ${row.getValue<ShopifyProduct>().title}`}
              />
            ) : (
              <div className="grid h-12 w-12 place-items-center rounded-lg border">
                ?
              </div>
            )}

            <span className="group-hover:underline">
              {row.getValue<ShopifyProduct>().title}
            </span>
          </div>
        </>
      ),
      header: "Produit Shopify",
    },
    {
      id: "shopify-product-status",
      accessorFn: (row) => row.product,
      cell: (row) => (
        <span className="self-end text-xs uppercase">
          <ShopifyProductStatusBadge
            status={row.getValue<ShopifyProduct>().status}
          />
        </span>
      ),
      header: "Status",
    },
    {
      id: "custom-lab-model",
      accessorFn: (row) => row,
      cell: (row) => {
        const productLink = row.getValue<ShopifyProductLink>();
        const mf = productLink.product.metafields.nodes.find(
          (mf) => mf.key === "custom_lab_model_id",
        );

        // if the product is associated with a deleted model
        if (mf && mf.value !== "none" && !productLink.model) {
          return (
            <span className="flex items-center gap-1.5 text-error">
              <TriangleAlert className="h-4 w-4 -translate-y-[1px]" /> Modèle
              supprimé ou corrompu
            </span>
          );
        }

        return (
          <span className="cursor-pointer group-hover:underline">
            {/* @ts-expect-error todo */}
            {productLink.model?.name ? getModelTitle(productLink.model) : "/"}
          </span>
        );
      },
      header: "Modèle Custom Lab",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Table className={cn(tableClassName)}>
      <TableCaption>
        Les associations Produits Shopify - Modèles Custom Lab
      </TableCaption>

      <TableHeader className={cn("table w-full table-fixed", headerClassName)}>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id} className="text-slate-700">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody
        id="scrollableDiv"
        className={cn(
          "block table-fixed overflow-y-auto overflow-x-hidden",
          bodyClassName,
        )}
        style={bodyStyle}
      >
        {loading ? (
          <div className="grid h-full place-items-center">
            <MxlabLoader
              color="black"
              text="Chargement des produits shopify..."
            />
          </div>
        ) : table.getRowModel().rows?.length ? (
          <InfiniteScroll
            dataLength={table.getRowModel().rows.length}
            next={nextPage}
            hasMore={hasNextPage}
            loader={
              <div className="grid place-items-center">
                <OvalLoader width={100} />
              </div>
            }
            endMessage={
              <div className="grid h-[100px] place-items-center">
                <div>
                  Tous les résultats sont affichés (
                  <span className="font-semibold">{products.length}</span>)
                </div>
              </div>
            }
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.9}
          >
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className="group relative table w-full table-fixed cursor-pointer odd:bg-gray-50 hover:bg-gray-100"
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="relative first:p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </InfiniteScroll>
        ) : (
          <TableRow className="table w-full table-fixed">
            <TableCell className="text-center" colSpan={columns.length}>
              Aucun résultat
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
