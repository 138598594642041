/* eslint-disable @typescript-eslint/no-explicit-any */

import { LocalizedNotice } from "@/types/model.ts";
import { ComponentProps, ComponentType } from "react";

export type CustomModelEditorMode = "read-only" | "edit";

export type StepBarItem = {
  readonly title: string;
  readonly component: ComponentType<any>;
  readonly componentProps?: ComponentProps<any>;
  readonly localizedNotice?: LocalizedNotice;
};

export enum CustomModelHistoryStatus {
  SAVING = "SAVING",
  SAVED = "SAVED",
  ERROR = "ERROR",
}