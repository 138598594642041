import { useConfirm } from "@/providers/AlertDialogProvider.tsx";
import { InfiniteHits } from "@components/ui/InfiniteHits.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { CustomModelHit, searchClient } from "@lib/algolia.ts";
import { api } from "@lib/api.ts";
import { cn } from "@lib/utils.ts";
import * as Sentry from "@sentry/react";
import { Ellipsis } from "lucide-react";
import { InstantSearch, RefinementList, Stats } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function AdminCustomModelsRoute() {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="custom-models">
        <RefinementList attribute="createdAt" />
        <Stats
          className="mb-2 text-black"
          translations={{
            rootElementText({ nbHits }) {
              return `${nbHits} résultats trouvés`;
            },
          }}
        />
        <InfiniteHits<CustomModelHit> HitComponent={Hit} />
      </InstantSearch>
    </div>
  );
}

function Hit({ hit }: { hit: CustomModelHit }) {
  const navigate = useNavigate();
  const confirm = useConfirm();

  function handleHitClick() {
    navigate(`/customize/${hit.objectID}`);
  }

  async function handleDeleteClick() {
    const confirmed = await confirm({
      title: `Supprimer la configuration ?`,
      body: "Le client ne pourra plus la modifier.",
      actionButton: "Supprimer",
      actionButtonVariant: "destructive",
    });

    if (confirmed) {
      const promise = api.deleteCustomModel(hit.objectID);
      toast.promise(promise, {
        loading: "Suppression de la configuration...",
        success: () => {
          window.location.reload();
          return "Configuration supprimée";
        },
        error: (error) => {
          console.error("Error while deleting custom model", error);
          Sentry.captureException(error, {
            tags: { section: "custom-models" },
          });
          return "Erreur lors de la suppression";
        },
      });
    }
  }

  return (
    <div className="relative overflow-hidden rounded-lg bg-[#323232] p-2">
      <div
        className="group grid cursor-pointer grid-rows-[200px_auto] items-center gap-3"
        onClick={handleHitClick}
      >
        <img
          className={cn(
            "h-full w-full object-contain duration-100 group-hover:scale-110",
          )}
          src={hit.image}
          alt={hit["model.name"]}
        />

        <div className="flex flex-col items-center gap-1">
          <div className="rounded-full bg-gray-500 px-2 text-sm">
            {hit["model.brand"]}
          </div>
          <div className="text-lg font-semibold">{hit["model.name"]}</div>
        </div>
      </div>

      {/* Actions dropdown */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="absolute right-1 top-1 h-10 w-10 p-0"
            variant="ghost"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuGroup className="*:flex *:justify-between *:gap-2 *:font-normal">
            {/* ID */}
            <DropdownMenuLabel>
              <span>ID:</span>
              <span>{hit.objectID}</span>
            </DropdownMenuLabel>
            {/* CreatedAt */}
            <DropdownMenuLabel>
              <span>Créé le:</span>
              <span>{new Date(hit.createdAt).toLocaleString("fr")}</span>
            </DropdownMenuLabel>
            {/* UpdatedAt */}
            {hit.updatedAt ? (
              <DropdownMenuLabel>
                <span>Dernière màj:</span>
                <span>{new Date(hit.updatedAt).toLocaleString("fr")}</span>
              </DropdownMenuLabel>
            ) : null}
          </DropdownMenuGroup>

          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <DropdownMenuItem
              className="text-error hover:!bg-error/10 hover:!text-error"
              onClick={handleDeleteClick}
            >
              Supprimer
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
