import { algoliasearch } from "algoliasearch";
import type { Hit as AlgoliaHit } from "instantsearch.js";

export const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY,
);

export type ModelHit = AlgoliaHit<{
  name: string;
  brand: string;
  image: string;

  shopifyProduct?: {
    id: string;
    handle: string;
    onlineStoreUrl?: string;
    onlineStorePreviewUrl: string;
  };

  createdAt: string;
  updatedAt?: string;
}>;

export function getModelHitTitle(hit: ModelHit): string {
  return hit.brand ? `${hit.brand} - ${hit.name}` : hit.name;
}

export type CustomModelHit = AlgoliaHit<{
  createdAt: string;
  updatedAt: string;
  "model.name": string;
  "model.brand": string;
  image: string;
}>;
