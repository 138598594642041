import { cn } from "@lib/utils.ts";
import { LoaderCircle, Search, X } from "lucide-react";
import { useRef, useState } from "react";
import {
  SearchBoxProps,
  useInstantSearch,
  useSearchBox,
} from "react-instantsearch";

export default function SearchBox({ className, ...props }: SearchBoxProps) {
  const { query, refine, clear } = useSearchBox(props);

  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  const { status } = useInstantSearch();
  const isSearchStalled = status === "stalled";

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  function reset() {
    setInputValue("");
    clear();
  }

  return (
    <div className={cn("relative w-fit", className)}>
      <input
        ref={inputRef}
        className={cn(
          "rounded border-gray-400 p-2 text-black focus:border-gray-400 focus:ring-2 focus:ring-offset-2",
          "min-w-[500px]",
        )}
        placeholder="Recherche"
        value={inputValue}
        onChange={(event) => {
          setQuery(event.currentTarget.value);
        }}
        onKeyDown={(event) => event.key === "Enter" && inputRef.current?.blur()}
        autoFocus
      />

      <div className="absolute right-3 top-1/2 grid -translate-y-1/2 place-items-center">
        {isSearchStalled ? (
          <LoaderCircle className="animate-spin text-gray-600" size={20} />
        ) : inputValue.length ? (
          <button onClick={reset}>
            <X className="text-gray-600" size={20} />
          </button>
        ) : (
          <Search className="text-gray-600" size={20} />
        )}
      </div>
    </div>
  );
}
